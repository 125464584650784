<template>
  <a-empty description="该功能暂未开放"></a-empty>
</template>

<script>
export default {
  data () {
    return {
      data: [],
      houseId: '',
      houseLink: ''
    }
  },
  created () {
    this.houseId = this.$route.query.id
    this.houseLink = this.$route.query.link
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
